@import "variable";

/* Position and sizing of burger button */
.bm-burger-button {
    position : fixed;
    width    : 36px;
    height   : 30px;
    left     : 24px;
    top      : 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background : var(--main-color);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background : darken($main-color, 10);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    button {
        height : 40px !important;
        width  : 40px !important;
        right  : 0 !important;
        left   : unset !important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background : var(--black-color);
        width      : 6px !important;
        height     : 28px !important;
        right      : 5px;
    }
}


/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position : fixed;
    height   : 100%;
    z-index: 1000;
}

/* General sidebar styles */
.bm-menu {
    background : var(--main-color);
    padding    : 3em 0;
    font-size  : 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill : #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color           : var(--main-color);
    padding         : 0 10px;
    //height: auto !important;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    height          : 100%;
}

/* Individual item */
.bm-item {
    text-decoration : none !important;
    text-align      : center;
    margin          : 10px 0;
    @media (min-width : 700px) {
        margin : 15px 0;
    }

    p {
        font-family     : "Bellota", cursive;
        text-align      : center;
        color           : var(--black-color);
        font-weight     : normal;
        position        : relative;
        display         : inline;
        text-transform  : uppercase;
        font-size       : 24px;
        text-decoration : none;
        @media (min-width : 700px) {
            margin    : 20px 0;
            font-size : 30px;
        }

    }
}

/* Styling of overlay */
.bm-overlay {
    background : rgba(0, 0, 0, 0.3);
}

.no-opacity {
    opacity : 0;
}


.selected, .bm-item:hover {
    p {
        font-weight : 700;

        &:after {
            position   : absolute;
            content    : "";
            height     : 2.5px;
            bottom     : -8px;
            margin     : 0 auto;
            left       : 0;
            right      : 0;
            width      : 75%;
            background : var(--black-color);
        }
    }
}

