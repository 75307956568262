.test {
	background: rebeccapurple;
}

.title-bold {
	font-weight: bold;
	text-align: center;
}

.center-img {
	display: block;
	margin: auto;
}

a:active {
	color: blue;
}

.no-pm {
	margin: 0;
	padding: 0
}

.bold {
	font-weight: 600;
}
