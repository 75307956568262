@import "variable";

.pimp-slick-dots {
	.slick-dots {
		position: absolute;
		bottom: 0;
		font-size: 0;
		padding: 0;

		li {
			height: 40px;
			width: 40px;
			margin: 0 20px 8px;
			position: relative;

			button {
				padding: 0;
				height: 0;
				width: 0;
				font-size: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				&:before {
					width: 50px;
					height: 8px;
					content: "";
					background: $main-color;
				}
			}
		}
	}
}


.slick-prev:before, .slick-next:before {
	color: gray;
}

.center-slider {
	.slick-track {
		display: flex;
	}

	.slick-track .slick-slide {
		display: flex;
		height: auto;
		align-items: center;
		justify-content: center;
	}
}

// list no working in js
.red-list {
	margin-left: 1em;

	&:before {
		content: '\2022';
		color: var(--main-color);
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
}
