
// common to all text
p, blockquote {
	@media (min-width: 992px) {
		font-size: 18px;
	}
	font-size: 14px
}

* {
	font-family: quickSand;
	color: var(--black-color);
	line-height: 1.5;
	outline: none;
}



// quote style
blockquote, .citation {
	quotes: "\201C""\201D""\2018""\2019";
}
